import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useForm } from '@formspree/react';
import { Button, Label, Input, Form, FormGroup, Row, Col } from 'reactstrap';
import { FiCheck, FiAlertCircle } from 'react-icons/fi';

const SecondPage = () => {
  const [state, handleSubmit] = useForm('mnqogzlo');

  return (
    <Layout>
      <SEO title="Contact" />
      <div id="container">
        <Row>
          <Col md={8} className="mx-auto">
            <h1>Contact me</h1>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" required />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input type="email" name="_replyto" id="email" required />
              </FormGroup>
              <FormGroup>
                <Label for="message">Message</Label>
                <textarea
                  className="form-control"
                  rows="5"
                  name="message"
                  id="message"
                  required></textarea>
              </FormGroup>
              <Button color="primary" disabled={state.submitting}>
                <span>Send</span>{state.errors && !!state.errors.length && <FiAlertCircle />}{state.succeeded && <FiCheck />}
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default SecondPage;
